import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Stories from 'react-insta-stories';
import * as portals from 'react-reverse-portal';
import './App.css';

import lifeLogo from './images/life_logo.svg';

import { useSprings, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react'; 
import clamp from 'lodash/clamp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft, faChurch } from '@fortawesome/free-solid-svg-icons'
import { act } from 'react-dom/test-utils';


const PUBLIC_URL = process.env.PUBLIC_URL;
const storiesPath = `${PUBLIC_URL}/stories`;
const titleCardsPath = `${PUBLIC_URL}/title-cards`

const segChurch = [
  {
    url: `${storiesPath}/what a year.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/3A.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/3B.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/3C.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/4A.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/4C.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/4D.mp4`,
    type: 'video'
  },
]

const segAus = [
  {
    url: `${storiesPath}/australia_title.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/6B.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/6D.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/13B2.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/7B.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/7D.mp4`,
    type: 'video'
  },
]

const segGiving = [
  {
    url: `${storiesPath}/8B.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/8C.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/8F.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/8A.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/8D.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/8E.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/9A.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/9B.mp4`,
    type: 'video'
  },
]

const segCom = [
  {
    url: `${storiesPath}/10A2.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/10B.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/10D.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/10E.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/10F_1.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/10H2.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/10J.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/10M.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/10N1.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/10N2.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/10K.mp4`,
    type: 'video'
  }
]

const segBus = [
  // {
  //   url: `${storiesPath}/11A.mp4`,
  //   type: 'video'
  // },
  {
    url: `${storiesPath}/12A.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/12B.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/12C.mp4`,
    type: 'video'
  },
  {
    url: `${storiesPath}/15A.mp4`,
    type: 'video'
  }
]

const segments = [
  segChurch,
  segAus,
  // segGiving,
  segCom,
  segBus
]

const segmentNames = [
  "Church",
  "Australia",
  // "Giving",
  "Community",
  "Business"
]

function App() {
  const index = useRef(0);
  const width = window.innerWidth;
  const height = window.innerHeight;

  const [landingPage, setLandingPage] = useState(1);

  const [activeSegment, setActiveSegment] = useState(0);
  const [storyIndex, setStoryIndex] = useState(0);
  const [hasUpdated, setHasUpdated] = useState(0);

  const forceUpdate = useCallback(node => {
    if(node) {
      setHasUpdated(true);
    }
  })

  // drag
  const [props, api] = useSprings(segments.length, i => ({
    x: i * ((height*0.5625) + 0),
    scale: 1,
    display: 'block',
  }))

  const bindDrag = useDrag(({ active, movement: [mx], direction: [xDir], cancel }) => {
    if (active && Math.abs(mx) > width / 3) {
      setActiveSegment(clamp(activeSegment + (xDir > 0 ? -1 : 1), 0, segments.length - 1));
      cancel()
    }
    api.start(i => {
      const x = (i - activeSegment) * ((height*0.5625) + 0) + (active ? mx : 0)
      let scale = active ? 1 - Math.abs(mx) / width : 1
      let display = 'block';
      if (i !== activeSegment) {
        display = 'none';
        scale = 0.6;
      }
      if (i === activeSegment - 1 && xDir > 0 && active) {
        scale = 0.6 + Math.abs(mx) / width;
      }
      if (i === activeSegment + 1 && xDir < 0 && active) {
        scale = 0.6 + Math.abs(mx) / width;
      }
      return { x, scale, display }
    })
  })

  const prevActiveSegment = useRef(0);

  useEffect(() => {
    if(activeSegment > prevActiveSegment.current) {
      setStoryIndex(0);
    }
    prevActiveSegment.current = activeSegment;
    api.start(i => {
      const x = (i - activeSegment) * ((height*0.5625) + 0)
      let scale = 1;
      let display = 'block';
      if (i < activeSegment || i > activeSegment) {
        display = 'none';
        scale = 0.6;
      }
      return { x, scale, display }
    })
  }, [activeSegment])

  useEffect(() => {
    console.log("storyindex", storyIndex)
  }, [storyIndex])

  function handleContinue() {
    setLandingPage(0);
  }


  return (
    <div className="App">
      {landingPage === 1 ? 
        <div>
          <div className='landing-bg'></div>
          <div className='pdj-msg'>
            <div className='pdj-mdj-portrait'></div>
            <div className='pdj-text'>
              <img src={lifeLogo} alt="life" className="landing-logo"/>
              <h1>Annual Report 2021</h1>
              <p>Hey Church,</p>

              <p>It is time for us to review the 2021 year.</p>

              <p>What a challenging time 2021 was, where for most of the year we were unable to be physically in church services. Much of what would be classed as normal shifted somewhat and presented some fairly major challenges.</p>

              <p>However, God is and remains faithful, and we are in awe of the many that have stepped up to serve the needs in our surrounding communities. </p>

              <p>We are thankful for God’s presence which continues to surround us, and a big shout out to the technical and creative teams and the many that made our online services such a God focused experience.</p>

              <p>Maree and I want to thank everyone who has continued to be faithful in playing their part to see what God has entrusted for us to carry, continue to happen and expand throughout 2021. I have often been reminded in the tough times that it is God who builds His church.</p>

              <div className='popout-text'><b>Matthew 16:18</b><br/>And I also say to you that you are Peter, and on this rock I will build My church, and the gates of Hades shall not prevail against it.</div>

              <p>Let’s all determine to bring the light of the Good News to our family, friends and those we come alongside in 2022.</p>

              <p>We love and appreciate you</p>

              <p><b>Ps Paul & Maree de Jong</b><br/> Founding Pastors</p>
              <div className="continue-btn__wrapper">
                <button className='continue-btn' onClick={handleContinue}>CONTINUE</button>
              </div>
            </div>
          </div>
        </div>
      :
      <div className="story-offset">
        {props.map(({x, display, scale }, i) => (
          <animated.div className="segment-container" ref={forceUpdate} data-update={hasUpdated} key={i} {...bindDrag()} style={{ x, scale, touchAction: 'none', zIndex: 200 }}>
            {/* <div className="segment-outer" style={{transform: `translateX(${(i-activeSegment)*90}%) scale(${i === activeSegment ? 1 : 0.6})`}}> */}
            <div className="segment-outer">
                <div>
                  <div className="segment-inner">
                      <Stories
                        stories={segments[i]}
                        defaultInterval={10000}
                        // maxWidth={"56.2vh"}
                        width={"100%"}
                        height={"100%"}
                        storyStyles={
                          {
                            width: '100%',
                            height: '100%'
                          }
                        }
                        keyboardNavigation={true}
                        loop={false}
                        currentIndex={storyIndex}
                        onStoryEnd={() => { 
                          if (activeSegment === i) {
                            setStoryIndex(storyIndex+1)
                          }
                        
                        }}
                        onAllStoriesEnd={() => { if (activeSegment < segments.length - 1) { setActiveSegment(activeSegment+1) }}}
                        preventDefault
                      />
                    <animated.div className="story-container" style={{display}}>
                    {storyIndex === segments[i].length - 1 ?
                      (
                        <div>
                          <div className='story-prev' onClick={() => { setStoryIndex(storyIndex-1) }}/>
                          <div className='story-next' onClick={() => { setActiveSegment(activeSegment+1) }}/>
                        </div>
                      )
                      :
                      (
                        <div>
                          { storyIndex <= 0 ?
                            <div className='story-prev' onClick={() => {  }}/>
                            :
                            <div className='story-prev' onClick={() => { setStoryIndex(storyIndex-1) }}/>
                          }
                          <div className='story-next' onClick={() => { setStoryIndex(storyIndex+1) }}/>
                        </div>
                      )
                    }
                    </animated.div>
                    <div className='desktop-facade'></div>
                    {activeSegment === i ? 
                      ''
                    :
                      <div className="title-card" onClick={() => {setActiveSegment(i)}} style={{backgroundImage: `url(${titleCardsPath}/title-cards_${segmentNames[i].toUpperCase()}.png)`, backgroundSize: 'cover'}} />
                    }
                  </div>
                  {activeSegment === i ? 
                    <div className='nav-btn-container'>
                    {storyIndex === 0 && activeSegment > 0 ?
                    (
                      <button className='prev-btn' onClick={() => { setStoryIndex(segments[activeSegment - 1].length - 1); setActiveSegment(activeSegment-1) }}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                      </button>
                    )
                    :
                      storyIndex === 0 ? 
                      (
                        ''
                      )
                      :
                      (
                        <button className='prev-btn' onClick={() => setStoryIndex(storyIndex-1)}>
                          <FontAwesomeIcon icon={faAngleLeft} />
                        </button>
                      )
                    }
                    {storyIndex < segments[i].length - 1 ? (
                      <button className='next-btn' onClick={() => setStoryIndex(storyIndex+1)}>
                        <FontAwesomeIcon icon={faAngleRight} />
                      </button>
                    )
                    :
                    activeSegment === segments.length - 1 ? 
                      (
                        ''
                      )
                      :
                      (
                        <button className='next-btn' onClick={() => {setStoryIndex(0); setActiveSegment(activeSegment+1) }}>
                          <FontAwesomeIcon icon={faAngleRight} />
                        </button>
                      )
                    }
                  </div>
                  :
                    ''
                  }
                </div>
            </div>
          </animated.div>
        ))}
      </div>
      }
    </div>
  );
}

export default App;
